import i18n from "@/core/i18n/i18n";

const { t } = i18n.global;

export interface CronData {
  from: {
    first: number,
    second: number,
  },
  to: {
    first: number,
    second: number,
  },
  specified: Array<string>,
  lValue?: number,
  hashValue?: {
    first: number,
    second: number,
  },
  wValue?: number,
}

export function getCronPart(symbol: string, dataObj: any): string {
  switch (symbol) {
    case "*":
      return "*";
    case "/":
      return `${dataObj.from.first}/${dataObj.from.second}`;
    case "-":
      return `${dataObj.to.first}-${dataObj.to.second}`;
    case ",":
      return listToString(dataObj.specified);
    case "?":
      return "?";
    case "DL":
      return `L-${dataObj.lValue}`;
    case "WL":
      return `${dataObj.lValue}L`;
    case "W":
      return `${dataObj.wValue}W`;
    case "LW":
      return "LW";
    case "#":
      return `${dataObj.hashValue.second}#${dataObj.hashValue.first}`;
    default:
      return "*";
  }
}

function listToString(list: []): string {
  let str = "";
  list.forEach((item, index) => {
    if (index === 0) {
      str = `${item}`;
    } else {
      str = `${str},${item}`;
    }
  });
  return str;
}

export function getSymbol(val: string): string {
  switch (true) {
    case val.includes("*"):
      return "*";
    case val.includes("/"):
      return "/";
    case val.includes("-"):
      return "-";
    case val.includes(","):
    case (!val.includes("*")
      && !val.includes("-")
      && !val.includes("/")
      && !val.includes("LW")
      && !val.includes("W")
      && !val.includes("#")
      && !val.includes("L")
      && !val.includes("?")):
      return ",";
    case val.includes("?"):
      return "?";
    case val.includes("#"):
      return "#";
    case val.includes("LW"):
      return "LW";
    case val.includes("L-"):
      return "DL";
    case val.endsWith("L"):
      return "WL";
    case val.endsWith("W"):
      return "W";
    default:
      return ",";
  }
}

export function generateCron(type: number, data: any): string {
  if (!data || type == null) {
    return "UNDEFINED_CRON";
  }
  switch (type) {
    case 1:
      return `${data.seconds} ${data.minutes}/${data.minValue} * 1/1 * ?`;
    case 2:
      return generateByHourType(data, data.hourType);
    case 3:
      return generateByDayType(data, data.dayType);
    case 4:
      return `0 ${data.startsAt.minute} ${data.startsAt.hour} ? * ${listToString(data.weeklyList)}`;
    case 5:
      return generateByMonthType(data, data.monthType);
    default:
      return "";
  }
}

function generateByHourType(data: any, innerType: string): string {
  if (!data || !innerType) {
    return "UNDEFINED_CRON";
  }
  switch (innerType) {
    case "hour-every":
      return `0 0 0/${data.hourlyValue.every} 1/1 * ?`;
    case "hour-every-time":
      return `0 ${data.startsAt.minute} ${data.startsAt.hour} 1/1 * ?`;
    default:
      return "0 0/30 * 1/1 * ? *";
  }
}

function generateByDayType(data: any, innerType: string): string {
  if (!data || !innerType) {
    return "UNDEFINED_CRON";
  }
  switch (innerType) {
    case "day-every":
      return `0 ${data.startsAt.minute} ${data.startsAt.hour} 1/1 * ?`;
    case "day-every-weekday":
      return `0 ${data.startsAt.minute} ${data.startsAt.hour} ? * MON-FRI`;
    case "day-every-weekend":
      return `0 ${data.startsAt.minute} ${data.startsAt.hour} ? * SAT-SUN`;
    default:
      return "0 0/30 * 1/1 * ? *";
  }
}

function generateByMonthType(data: any, innerType: string): string {
  if (!data || !innerType) {
    return "UNDEFINED_CRON";
  }
  switch (innerType) {
    case "month-1":
      // eslint-disable-next-line max-len
      return `0 ${data.startsAt.minute} ${data.startsAt.hour} ${data.monthlyValue.day.first} 1/${data.monthlyValue.day.second} ?`;
    case "month-2":
      // eslint-disable-next-line max-len
      return `0 ${data.startsAt.minute} ${data.startsAt.hour} ? 1/${data.monthlyValue.the.third} ${data.monthlyValue.the.second}#${data.monthlyValue.the.first}`;
    default:
      return "0 0/30 * 1/1 * ? *";
  }
}

export function getData(item: string, symbol: string): any {
  const cronData: CronData = {
    from: {
      first: 0,
      second: 0,
    },
    to: {
      first: 0,
      second: 0,
    },
    specified: [],
    lValue: 0,
    hashValue: {
      first: 0,
      second: 0,
    },
    wValue: 0,
  };
  if (symbol === "/") {
    const values = item.split(symbol);
    cronData.from.first = Number(values[0]);
    cronData.from.second = Number(values[1]);
    return cronData;
  }
  if (symbol === "-") {
    const values = item.split(symbol);
    cronData.to.first = Number(values[0]);
    cronData.to.second = Number(values[1]);
    return cronData;
  }
  if (symbol === ",") {
    if (!item.includes(symbol)) {
      cronData.specified.push(item);
      return cronData;
    }
    const values = item.split(symbol);
    values.forEach((val) => {
      cronData.specified.push(val);
    });
    return cronData;
  }
  if (symbol === "#") {
    const values = item.split(symbol);
    cronData.hashValue.first = Number(values[1]);
    cronData.hashValue.second = Number(values[0]);
    return cronData;
  }
  if (symbol === "DL") {
    const values = item.split("L-");
    cronData.lValue = Number(values[0]);
    return cronData;
  }
  if (symbol === "WL") {
    const values = item.split("L");
    cronData.lValue = Number(values[0]);
    return cronData;
  }
  if (symbol === "W") {
    const values = item.split(symbol);
    cronData.wValue = Number(values[0]);
    return cronData;
  }
  return cronData;
}

export const weekDays = [
  {
    day: t("Schedule.Sunday"),
    index: 1,
  },
  {
    day: t("Schedule.Monday"),
    index: 2,
  },
  {
    day: t("Schedule.Tuesday"),
    index: 3,
  },
  {
    day: t("Schedule.Wednesday"),
    index: 4,
  },
  {
    day: t("Schedule.Thursday"),
    index: 5,
  },
  {
    day: t("Schedule.Friday"),
    index: 6,
  },
  {
    day: t("Schedule.Saturday"),
    index: 7,
  },
];

export const defaultCronExp = [
  {
    text: "Every30Min",
    value: "0 0/30 * * * ?",
  },
  {
    text: "EveryHourQuarterMin",
    value: "0 15,30,45 * * * ?",
  },
  {
    text: "EveryOddHour",
    value: "0 0 1/2 ? * *",
  },
  {
    text: "EveryEvenHour",
    value: "0 0 0/2 ? * *",
  },
  {
    text: "EveryDayMidnight",
    value: "0 0 0 * * ?",
  },
  {
    text: "EveryDayNoon",
    value: "0 0 12 * * ?",
  },
  {
    text: "EveryMondayNoon",
    value: "0 0 12 ? * MON",
  },
  {
    text: "EveryWeekdayNoon",
    value: "0 0 12 ? * MON-FRI",
  },
  {
    text: "EveryLastDayMonth",
    value: "0 0 12 L * ?",
  },
  {
    text: "EveryMonthOnLastSunday",
    value: "0 0 12 ? * 1L",
  },
];

export const helpSection = [
  {
    name: "Second",
    value: "0 1 2 ... 59",
    symbol: "* / - ,",
  },
  {
    name: "Minute",
    value: "0 1 2 ... 59",
    symbol: "* / - ,",
  },
  {
    name: "Hour",
    value: "0 1 2 ... 23",
    symbol: "* / - ,",
  },
  {
    name: "Day",
    value: "0 1 2 ... 31",
    symbol: "* / -, L W C",
  },
  {
    name: "Month",
    value: "1 2 ... 12 or JAN ... DEC",
    symbol: "* / - ,",
  },
  {
    name: "Week",
    value: "1 2 ... 7 or SUN ... SAT",
    symbol: "* / -, ? # L",
  },
];
